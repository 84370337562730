import React from 'react'
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import '../styles/notFound.scss'

const getImage = (images, name) => images.find(
  image => image.originalName === `${name}.png`
)

const notFound = ({lang, images, ...props}) => {
  return (
    <div className="NotFound">
      <div className="NotFound-container">
        <div className="NotFound-404Container">
          <span>4</span>
            <Img
              className="NotFound-face"
              alt="face"
              sizes={getImage(images, 'face')}
            />
          <span>4</span>
        </div>
        <div className="NotFound-error">{lang.error.toUpperCase()}</div>
        <div className="NotFound-oops">Ooooops!</div>
        <div className="NotFound-notFound">
          {lang.notFoundPage.toUpperCase()}
        </div>
        <Link to={`/${lang.lang}/`}>
          <button type="button" className="NotFound-btn">
            {lang.goToHome.toUpperCase()}
          </button>
        </Link>
      </div>
    </div>
  )
}

export default notFound
