import React from 'react'
import { getUserLangKey } from 'ptz-i18n'
import { graphql, withPrefix, navigate } from 'gatsby'

import Layout from '../components/layout'
import NotFound from '../components/notFound'
import esLang from '../data/es.json'
import enLang from '../data/en.json'

export default class NotFoundPage extends React.PureComponent {
  render() {
    const { location, data } = this.props
    const { langs, defaultLangKey } = this.props.data.site.siteMetadata.languages
    return (
      <Layout
        lang={getUserLangKey(langs, defaultLangKey) === 'es' ? esLang : enLang}
        location={location}
        component={NotFound}
        logo={data.logo.sizes}
        images={data.allImageSharp.nodes.map(node => node.sizes)}
        noShowQuote
        noShowContactTile
      />
    )
  }
}

export const pageQuery = graphql`
  query notFoundPage {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }

    logo: imageSharp(fluid: { originalName: { eq: "logo.png" }}) {
      sizes(quality: 100, maxWidth: 75, maxHeight: 50, cropFocus: CENTER){
        ...GatsbyImageSharpSizes_withWebp
      }
    }

    allImageSharp(
      filter: { fluid: { originalName: { glob: "{bg,face}*.png" } } }
    ) {
      nodes {
        sizes(quality: 100, maxWidth: 1800) {
          ...GatsbyImageSharpSizes_withWebp
          originalImg
          originalName
        }
      }
    }
  }
`
